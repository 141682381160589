import { ModelApiKey } from '../types/models'
import { LocaleCode } from '../types/locales'

export const paths = {
  [LocaleCode.EN]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'news' },
    [ModelApiKey.ProgramProduct]: { path: 'program-product' },
    [ModelApiKey.ShopProduct]: { path: 'shop/products' },
    [ModelApiKey.ShopCategory]: { path: 'shop' }
  },
  [LocaleCode.SL]: {
    [ModelApiKey.Page]: { path: '' },
    [ModelApiKey.Article]: { path: 'novice' },
    [ModelApiKey.ProgramProduct]: { path: 'program-product' },
    [ModelApiKey.ShopProduct]: { path: 'trgovina/izdelki' },
    [ModelApiKey.ShopCategory]: { path: 'trgovina' }
  }
}
